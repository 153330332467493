import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';


export default function SkeletonLoader(length) {
    const placeholders = [];
    for (let i = 0; i < length; i++) {
        placeholders.push(<Skeleton key={i} variant="text" sx={{ fontSize: '1rem' }} />)
    }
    return (
        <Box sx={{ padding: "2rem" }}>
            <Stack spacing={1} >{placeholders}</Stack>
        </Box>);
}
