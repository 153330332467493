const contactsArray = [
  {
    country: "Italy",
    address: "Via degli Alpini 14, Limana, 32020 - Limana (BL)",
    phone: "📞 800 11 08 11",
    email: "service@epta-italia.com",
  },
  {
    country: "France",
    address: "7 Allée de l'Industrie, 64700 - Hendaye",
    phone: "📞 +33 (0)5 59 51 62 62",
    email: "service@epta-france.com",
  },
  {
    country: "Germany",
    address: "Ludolf-Krehl-Straße 7-11, 68167 - Mannheim",
    phone: "📞 0800 522 43 32",
    email: "service@epta-deutschland.com",
  },
  {
    country: "UK",
    address:
      "Avonside House, Kingfisher Park, Headlands Business Park, Blashford, BH24 3NX Ringwood",
    phone: "📞 01 425485757",
    email: "service@epta-uk.com",
  },
  {
    country: "Spain",
    address: "Epta Iberia - Anselm Clavé 2, 1° Planta - 08500 Vic, Barcelona",
    phone: "📞 +34 938867711",
    email: "service@epta-iberia.com",
  },
  {
    country: "International",
    address: "Váci út 35. - 1134 Budapest - Hungary",
    phone: "📞 +36 1 8861 636",
    email: "service@epta-international.com",
  },
  {
    country: "Denmark",
    address: "Sandvadsvej 5, 4600 Koege",
    phone: "📞 +45 5664 6363",
    email: "kk-servicemail@knudsenkoling.dk",
  },
  {
    country: "Polska",
    address: "Ludwinowska 11/23, 30-331 Krakow",
    phone: "📞 +48 12 656 05 67 5",
    email: "service@epta-polska.com",
  },
  {
    country: "Romania",
    address: "Daniel Danielopolu, 30-32 etajul 1, Sector 1 - 077190 București",
    phone: "📞 +40 0374 210 018",
    email: "service@epta-romania.com",
  },
  {
    country: "UAE",
    address: "The Prism Tower, Office 1108, Business Bay - 57P6+XCX Dubai",
    phone: "📞 +971 4 5667889",
    email: "service.middleeast@epta-international.com",
  },
  {
    country: "Portugal",
    address:
      "Ind. Maia, Sector IV - Nº 104, Lote 5-B - 4470-461 Moreira da Maia",
    phone: "📞 (+351) 229 039 836/7",
    email: "service@epta-portugal.pt",
  },
  {
    country: "Singapore",
    address: "175 Kaki Bukit Avenue 1 - #04-00, Singapore 416022",
    phone: "📞 +65 6746 5966",
    email: "service@epta-asiapacific.com",
  },
  {
    country: "Philippines",
    address:
      "7/F Multinational Bancorporation, 6805 Ayala Avenue, Makati City, 1226 Philippines",
    phone: "📞 +63 956 120 6560",
    email: "service.philippines@epta-asiapacific.com",
  },
  {
    country: "Thailand",
    address:
      "231 Moo 6 Petchakasem Rd., Tambon Kao-Yai, Amphoe Cha-am, Petchburi 76120 - Thailand",
    phone: "📞 +66 32 453 220",
    email: "service.thailand@epta-asiapacific.com",
  },
  {
    country: "Australia",
    address: "Unit 2, 5 Salisbury Road, Castle Hill, NSW, 2154, Australia",
    phone: "📞 +61 (0)2 9659 5428 (Main)",
    email: "service.australia@epta-asiapacific.com",
  },
  {
    country: "China",
    address:
      "No. 72, XinYe Road, Qingdao Hi-Tech Industrial Develop. Zone (Hongdao), 266114 Qingdao, China",
    phone: "📞 +86 532 6696 3511",
    email: "service.china@epta-asiapacific.com",
  },
  {
    country: "New Caledonia",
    address:
      "8 Avenue des Géomètres Pionniers B.P 4127, 98 839 Dumbea, Nouvelle Calédonie",
    phone: "📞 (687) 23 10 42",
    email: "service.newcaledonia@epta-asiapacific.com",
  },
  {
    country: "Vietnam",
    address:
      "45 Phạm Vấn, Phường Phú Thọ Hoà, Quận Tân Phú, Thành phố Hồ Chí Minh, Việt Nam",
    phone: "📞 02836363860",
    email: "service.vietnam@epta-asiapacific.com",
  },
];

export default contactsArray;