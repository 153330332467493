import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { Home } from './components/Home';
// import { SerialNumberResolver } from './components/helpers/SerialNumberResolver'; removed -> no more supported use case MP
import { TagResolver } from "./components/helpers/TagResolver";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:tagId" element={<TagResolver />} />
        <Route path="/:tagId/:verificationCode" element={<TagResolver />} />
      </Routes>
    </BrowserRouter >
  );
}

export default App;
