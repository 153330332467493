import * as React from "react";
import Box from "@mui/material/Box";
import { createSvgIcon } from "@mui/material/utils";

const EnergyClassC = createSvgIcon(
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    x={-28}
    y={2}
    width={80}
    height={20}
    viewBox="0 0 104.7 57.5"
    style={{
      enableBackground: "new 0 0 104.7 57.5",
    }}
    xmlSpace="preserve"
  >
    <style>
      {
        ".st0{fill:#fff}.st5{fill:none;stroke:#1c1d00;stroke-width:2.218;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    <path className="st0" d="M.2.3H24V57H.2z" />
    <path
      style={{
        fill: "#c7d300",
      }}
      d="M78.8.8H22.4v55.9h56.4l25.7-28z"
    />
    <path
      d="M78.3 57.5H.8c-.5 0-.8-.4-.8-.8V.8C0 .3.4 0 .8 0h77.4c.2 0 .5.1.6.3l25.7 28c.3.3.3.8 0 1.1l-25.7 28c-.1 0-.3.1-.5.1zM.8.8v55.9h77.4l25.7-28L78.2.7.8.8z"
      style={{
        fill: "#1d1d1b",
      }}
    />
    <path
      className="st0"
      d="M60.1 39.4v.9c0 .2 0 .5-.1.6 0 .2-.1.3-.2.5-.1.1-.2.3-.3.4-.1.2-.4.4-.8.6s-.9.5-1.5.7-1.3.4-2.1.6c-.8.2-1.6.2-2.5.2-1.8 0-3.3-.3-4.7-.9s-2.6-1.5-3.6-2.6c-1-1.2-1.7-2.6-2.3-4.3s-.8-3.8-.8-6.1c0-2.4.3-4.4.9-6.3s1.4-3.4 2.4-4.6 2.3-2.2 3.7-2.8 3.1-1 4.8-1c.7 0 1.4.1 2.1.2.7.1 1.3.3 1.8.5s1.1.4 1.5.7c.4.3.8.5.9.7s.3.4.4.5c.1.1.1.3.2.5 0 .2.1.4.1.7v2.1c0 .3-.1.5-.1.7-.1.2-.1.3-.2.4-.1.1-.2.1-.3.1-.2 0-.4-.1-.8-.4-.3-.2-.7-.5-1.2-.8s-1.1-.6-1.7-.8c-.7-.2-1.5-.4-2.4-.4-1 0-1.9.2-2.7.7s-1.5 1.1-2 1.9c-.6.8-1 1.8-1.3 3s-.4 2.5-.4 4c0 1.6.2 3 .5 4.2s.8 2.2 1.3 2.9c.6.8 1.3 1.3 2.1 1.7s1.7.6 2.7.6c.9 0 1.7-.1 2.4-.4s1.2-.5 1.7-.8.9-.5 1.2-.8c.3-.2.5-.3.7-.3.1 0 .2 0 .3.1s.1.2.2.3.1.4.1.7v1.6z"
    />
    <path
      d="M60.1 38.2c0-.3-.1-.5-.1-.7-.1-.2-.1-.3-.2-.3-.1-.1-.2-.1-.3-.1-.2 0-.4.1-.7.3s-.7.5-1.2.8-1.1.5-1.7.8c-.7.2-1.5.4-2.4.4-1 0-1.9-.2-2.7-.6-.8-.4-1.5-.9-2.1-1.7s-1-1.7-1.3-2.9-.5-2.6-.5-4.2c0-1.5.1-2.8.4-4 .3-1.2.7-2.2 1.3-3s1.2-1.5 2-1.9 1.7-.7 2.7-.7c.9 0 1.7.1 2.4.4.7.2 1.2.5 1.7.8s.9.6 1.2.8c.3.2.6.4.8.4.1 0 .2 0 .3-.1s.2-.2.2-.4c.1-.2.1-.4.1-.7v-2.1c0-.3-.1-.5-.1-.7s-.1-.4-.2-.5c-.1-.1-.2-.3-.4-.5s-.5-.4-.9-.7c-.4-.3-1-.5-1.5-.7-.6-.2-1.2-.4-1.8-.5-.7-.1-1.3-.2-2.1-.2-1.8 0-3.4.3-4.8 1-1.5.6-2.7 1.6-3.7 2.8-1 1.2-1.9 2.8-2.4 4.6-.6 1.8-.9 3.9-.9 6.3 0 2.3.3 4.3.8 6.1.5 1.7 1.3 3.2 2.3 4.3 1 1.2 2.2 2 3.6 2.6 1.4.6 3 .9 4.7.9.9 0 1.7-.1 2.5-.2.8-.2 1.5-.4 2.1-.6.6-.2 1.1-.5 1.5-.7.4-.3.7-.5.8-.6.1-.2.2-.3.3-.4.1-.1.1-.3.2-.5 0-.2.1-.4.1-.6v-2.5z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 0.526,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M18.4 17.4c.1.3.1.5.2.6 0 .2 0 .3-.1.3-.1.1-.2.1-.4.1H16.4c-.2 0-.4 0-.5-.1-.1 0-.2-.1-.2-.1s-.1-.1-.1-.2l-.9-2.8H9.3L8.4 18c0 .1-.1.2-.1.3 0 .1-.1.1-.2.2s-.3.1-.4.1H6.1c-.2 0-.3-.1-.4-.2s-.1-.2-.1-.4.1-.4.2-.6L10.1 5c0-.1.1-.2.2-.3s.2-.1.3-.2c.1 0 .3-.1.5-.1H13c.3 0 .4 0 .6.1.1 0 .2.1.3.2 0 0 .1.1.1.3l4.4 12.4zM11.9 7.1l-2 6h4l-2-6z"
      style={{
        fill: "#1d1c1b",
      }}
    />
    <path className="st5" d="M12.1 34.9v-12M15.2 26l-3.1-3.1L8.9 26" />
    <path
      d="M17.5 41.3v.5c0 .1 0 .3-.1.4 0 .1-.1.2-.1.2s-.1.1-.2.1-.2-.1-.4-.2-.4-.3-.8-.4c-.3-.1-.7-.3-1.1-.4-.4-.1-.9-.2-1.5-.2s-1.2.1-1.7.3-.9.6-1.3 1-.6.9-.8 1.5-.3 1.3-.3 2c0 .8.1 1.5.3 2.1s.5 1.1.8 1.5.8.7 1.3.9 1 .3 1.6.3c.3 0 .6 0 .9-.1.3-.1.5-.2.8-.3v-3.2h-2.4c-.1 0-.2-.1-.3-.2-.1-.2-.1-.4-.1-.8v-.5c0-.1 0-.2.1-.3s.1-.1.1-.2c0 0 .1-.1.2-.1h4.3c.1 0 .2 0 .3.1.1 0 .2.1.2.2.1.1.1.2.1.3v5.9c0 .2 0 .4-.1.6s-.2.3-.4.4-.5.2-.8.3c-.3.1-.6.2-1 .3-.3.1-.7.1-1 .2s-.7.1-1 .1c-1 0-1.9-.2-2.7-.5s-1.5-.8-2-1.4-1-1.3-1.3-2.2-.4-1.8-.4-2.9c0-1.1.2-2.1.5-3.1.3-.9.8-1.7 1.3-2.3.6-.6 1.3-1.1 2.1-1.4.8-.3 1.7-.5 2.7-.5.6 0 1.1 0 1.5.1.5.1.9.2 1.2.3.3.1.6.2.8.4s.4.3.5.4c.1.1.2.2.2.4v.4z"
      style={{
        fill: "#1d1c1b",
      }}
    />
  </svg>
);

export default EnergyClassC;
