import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { API } from "aws-amplify";
import SkeletonLoader from "./helpers/SkeletonLoader";
import InfoAlert from "./helpers/InfoAlert";
import LaunchIcon from "@mui/icons-material/Launch";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
export function EquipmentUrl({ serialNumber }) {
  const [url, setUrl] = useState({ loading: true, data: [] });
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    setUrl({ loading: true });
    const fetchData = async () => {
      const payload = {
        queryStringParameters: {
          serial_number: serialNumber,
        },
      };
      const data = await API.post("NFClandingAPI", "/getEquipmentUrl", payload);
      setUrl({ loading: false, data: data });
    };

    fetchData();
  }, [serialNumber]);

  return (
    <Card sx={{ padding: "1rem" }}>
      {url.loading ? (
        SkeletonLoader(1)
      ) : url.data.success ? (
        <Button
          sx={{ width: "100%", p:2, }}
          variant="outlined"
          target="_blank"
          href={url.data.url}
          endIcon={<LaunchIcon />}
        >
          EXPLORE REAL TIME ANALYTICS ON SWITCHON
        </Button>
      ) : (
        <InfoAlert
          title={<strong>Unlock Full Potential with SwitchON</strong>}
          message={
            <>
              <Typography variant="body1" gutterBottom>
                Currently, you're viewing the basic info of your equipment. Did
                you know there's much more to explore? With{" "}
                <strong>SwitchON</strong>, gain access to detailed data
                analytics, comprehensive graphs, and real-time monitoring to
                maximize your equipment's efficiency and productivity.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Interested in elevating your experience? Let's connect!
                <br />
                <Box textAlign="center">
                <Button
                href="mailto:eptadataplatform@eptarefrigeration.com"
                startIcon={<MailOutlineIcon />}
                  sx={{
                    mt:2,
                    backgroundColor: `#2C9AE1`,
                    color: `white`,
                    "&:hover": {
                      backgroundColor: `rgba(204, 204, 204,1.0)`,
                      color: `#000`,
                    }
                  }}
                >
                  Find out more about SwitchON
                </Button>
                </Box>
              </Typography>
            </>
          }
        />
      )}
    </Card>
  );
}
