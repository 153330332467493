import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import DialogTitle from "@mui/material/DialogTitle";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { API } from "aws-amplify";
import SkeletonLoader from "./helpers/SkeletonLoader";
import InfoAlert from "./helpers/InfoAlert";
import { EquipmentUrl } from "./EquipmentUrl";
import IconButton from "@mui/material/IconButton";
import EnergyClassA from "./svgr/EnergyClasses/EnergyClassA";
import EnergyClassB from "./svgr/EnergyClasses/EnergyClassB";
import EnergyClassC from "./svgr/EnergyClasses/EnergyClassC";
import EnergyClassD from "./svgr/EnergyClasses/EnergyClassD";
import EnergyClassE from "./svgr/EnergyClasses/EnergyClassE";
import EnergyClassF from "./svgr/EnergyClasses/EnergyClassF";
import EnergyClassG from "./svgr/EnergyClasses/EnergyClassG";
import Button from "@mui/material/Button";
import LaunchIcon from "@mui/icons-material/Launch";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export function Equipment({ serialNumber, non_epta }) {
  const [equipment, setEquipment] = useState({ loading: true, data: [] });
  const theme = useTheme();
  const mobileDevice = !useMediaQuery(theme.breakpoints.up("lg"));
  const handleLogoClick = () => {
    window.location.reload();
  };
  useEffect(() => {
    setEquipment({ loading: true });
    const fetchData = async () => {
      const payload = {
        queryStringParameters: {
          serial_number: serialNumber,
        },
      };
      const data = await API.post("NFClandingAPI", "/getNFCEquipment", payload);
      setEquipment({ loading: false, data: data.length > 0 ? [data[0]] : [] });
    };
    if (non_epta) {
      //non_epta means that we cannot retrieve data from epta source, the data was insertend during nfc-tag association
      const non_epta_equipment = {
        numero_di_serie2: non_epta.serial_number,
        anno_di_costruzione: non_epta.manufacturer_year,
        produttore: non_epta.manufacturer_name,
        categoria_equipment: non_epta.classification_value,
      };
      setEquipment({ loading: false, data: [non_epta_equipment] });
    } else {
      fetchData();
    }
  }, [serialNumber, non_epta]);

  const tableData = [
    { label: "Equipment", key: "equipment" },
    { label: "Serial Number", key: "numero_di_serie2" },
    { label: "Description", key: "descrizione_equipment" },
    //{ label: "Equipment Category", key: "categoria_equipment" },
    { label: "Technical Type", key: "tipo_ogg_tecnico" },
    { label: "Producer", key: "produttore" },
    { label: "Year of construction", key: "anno_di_costruzione" },
    { label: "Energy Label", key: "energy_label" },
  ];

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      sx={{
        
        minHeight: "100vh",
        backgroundImage: `linear-gradient(to bottom, rgba(215, 121, 0, 1), rgba(120, 40, 0, 1)) !important;`,
      }}
    >
      <Grid item xs={12} md={6}>
        <a href="/" onClick={handleLogoClick}>
          <img
            style={{ maxWidth: "250px" }}
            alt="Epta Logo"
            src={require("../assets/img/LOGO-EptaService_White_Ho_3D_CMYK.webp")}
          />
        </a>
        <Box
          sx={{
            width: mobileDevice ? "90%" : "100%",
            marginX: "auto",
          }}
        >
          <Card>
            <DialogTitle>Equipment Details</DialogTitle>
            {equipment.loading ? (
              SkeletonLoader(tableData.length)
            ) : (
              <Container
                sx={{
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      {!serialNumber && (
                        <InfoAlert
                          message={`The provided Tag Identifier is not available`}
                        />
                      )}
                      {equipment.data.length === 0 && serialNumber ? (
                        <InfoAlert
                          message={`The Equipment with Serial Number ${serialNumber} is not available`}
                        />
                      ) : (
                        equipment.data.map((e) =>
                          tableData.map((row) =>
                            row.key === "tipo_ogg_tecnico" &&
                            e[row.key] !== null ? (
                              <TableRow key={row.key}>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    borderRight: `1px solid rgba(224, 224, 224, 1)`,
                                  }}
                                  component="th"
                                  scope="row"
                                  align="left"
                                  width="30%"
                                >
                                  {row.label}
                                </TableCell>
                                <TableCell align="left" width="70%">
                                  {equipment.data.map((e) => e[row.key])}
                                </TableCell>
                              </TableRow>
                            ) : (
                              row.key !== "energy_label" &&
                              row.key !== "tipo_ogg_tecnico" && (
                                <TableRow key={row.key}>
                                  <TableCell
                                    sx={{
                                      fontWeight: "bold",
                                      borderRight: `1px solid rgba(224, 224, 224, 1)`,
                                    }}
                                    component="th"
                                    scope="row"
                                    align="left"
                                    width="30%"
                                  >
                                    {row.label}
                                  </TableCell>
                                  <TableCell align="left" width="70%">
                                    {equipment.data.map((e) => e[row.key])}
                                  </TableCell>
                                </TableRow>
                              )
                            )
                          )
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <>
                  {equipment.data &&
                    equipment.data.length > 0 &&
                    serialNumber &&
                    equipment.data.map((e) =>
                      tableData.map((row) =>
                        row.key === "energy_label" &&
                        e[row.key] !== undefined &&
                        e[row.key]["energy_label"] !== undefined ? (
                          <Button
                            sx={{ width: mobileDevice ? "100%" : "40%", mt:2, display:"flex", justifyContent: "center", alignItems:"center" }}
                            variant="outlined"
                            target="_blank"
                            href={e[row.key]["url"]}
                            endIcon={(() => {
                              switch (e[row.key]["energy_label"]) {
                                case "A":
                                  return (
                                    <EnergyClassA
                                      sx={{ width: 80, height: 40, ml: -1, p:1 }}
                                    />
                                  );
                                case "B":
                                  return (
                                    <EnergyClassB
                                      sx={{ width: 80, height: 40, ml: -1 }}
                                    />
                                  );
                                case "C":
                                  return (
                                    <EnergyClassC
                                      sx={{ width: 80, height: 40, ml: -1 }}
                                    />
                                  );
                                case "D":
                                  return (
                                    <EnergyClassD
                                      sx={{ width: 80, height: 40, ml: -1 }}
                                    />
                                  );
                                case "E":
                                  return (
                                    <EnergyClassE
                                      sx={{ width: 80, height: 40, ml: -1 }}
                                    />
                                  );
                                case "F":
                                  return (
                                    <EnergyClassF
                                      sx={{ width: 80, height: 40, ml: -1 }}
                                    />
                                  );
                                case "G":
                                  return (
                                    <EnergyClassG
                                      sx={{ width: 80, height: 40, ml: -1 }}
                                    />
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          >
                            <Box>ENERGY LABEL</Box> <Box sx={{ml:1, mr:1, display:"flex", justifyContent: "center", alignItems:"center"}}><LaunchIcon /></Box>
                          </Button>
                        ) : null
                      )
                    )}
                </>
              </Container>
            )}
          </Card>

          <Box sx={{ marginTop: "1rem", paddingBottom: "1rem" }}>
            {serialNumber && <EquipmentUrl serialNumber={serialNumber} />}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
