import * as React from "react";
import Grid from "@mui/material/Grid";
import { Container, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import contactsArray from "./helpers/ContactsArray.js";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import useMediaQuery from "@mui/material/useMediaQuery";

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: `rgba(215, 121, 0,1.0)`,
  fontSize: `22px`,
  padding: `20px`,
  "&:hover": {
    backgroundColor: `rgba(204, 204, 204,1.0)`,
    color: `#000`,
  },
  minWidth: `200px`,
  minHeight: `50px`,
  maxWidth: `250px`,
  maxHeight: `60px`,
}));

export function Home() {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const handleLogoClick = () => {
    window.location.reload();
  };
  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(215, 121, 0, 1), rgba(120, 40, 0, 1)) !important;`,
        }}
      >
        <Grid
          container
          sx={{ pt: 10, pb: 10, justifyContent: "center" }}
          spacing={2}
        >
          <Grid item lg={12}>
            <Stack
              alignItems="center"
              justifyContent="center"
              direction={largeScreen ? "row" : "column"}
            >
              <Stack>
                <a href="/" onClick={handleLogoClick}>
                  <img
                    style={{ maxWidth: "250px" }}
                    alt="Epta Logo"
                    src={require("../assets/img/LOGO-EptaService_White_Ho_3D_CMYK.webp")}
                  />
                </a>
                <img
                  style={largeScreen ? { float: "right", maxWidth: "450px" } :{ float: "right", maxWidth: "350px" }}
                  alt="Epta technician"
                  src={require("../assets/img/EptaService-technician_2.webp")}
                />
              </Stack>

              <Box>
                <Typography
                  variant="h2"
                  textAlign={largeScreen ? "left" : "center"}
                  sx={{ marginTop: "1rem", color: "#fff" }}
                >
                  Welcome to EptaService
                </Typography>
                <Typography
                  variant="h5"
                  textAlign={largeScreen ? "left" : "center"}
                  sx={{ marginTop: "1rem", color: "#fff" }}
                >
                  You succesfully scanned the EptaService {<strong>NFC</strong>}
                  !
                </Typography>
                <Typography
                  variant="h5"
                  textAlign={largeScreen ? "left" : "center"}
                  sx={{ marginTop: "1rem", color: "#fff" }}
                >
                  Scroll down and{" "}
                  {<strong>get more information about the cabinet.</strong>}!
                  <br/><br/>Are you looking for NFC Management App? Click <a style={{color: "white"}} href="https://dev.d1ib1r58xbnrgl.amplifyapp.com">here</a>.
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{
          pt: "20px",
        }}
        maxWidth={false}
      >
        <Grid
          container
          sx={{
            pt: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={2}
        >
          <Grid item lg={6}>
            <img
              style={largeScreen ? { float: "right", maxWidth: "585px" } : { float: "right", maxWidth: "320px" }}
              alt="Epta Logo"
              src={require("../assets/img/NFC_Serial-number.webp")}
            />
          </Grid>
          <Grid item lg={6}>
            <Typography
              variant="h2"
              textAlign={largeScreen ? "left" : "center"}
              sx={{ marginTop: "1rem", color: "#444444", fontSize: "44px" }}
            >
              The cabinet serial number is important. Keep it!
            </Typography>
            <Typography
              variant="h5"
              textAlign={largeScreen ? "left" : "center"}
              sx={{ marginTop: "1rem", color: "#444444", fontSize: "22px" }}
            >
              Please, insert the serial number applied on the cabinet and use it
              to get:
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{
          pt: "20px",
          pb: "20px",
        }}
        maxWidth={false}
      >
        <Grid
          container
          sx={{
            pt: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={2}
        >
          <Grid item lg={4}>
            <Stack alignItems="center" justifyContent="center" spacing={2}>
              <a href="https://documents.eptarefrigeration.com/">
                <img
                  style={largeScreen ? { maxWidth: "380px" } : { maxWidth: "300px" }}
                  alt="Epta Logo"
                  src={require("../assets/img/NFC_Paperless.webp")}
                />
              </a>
              <Typography
                variant="h2"
                textAlign={largeScreen ? "left" : "center"}
                sx={{ marginTop: "1rem", color: "#444444", fontSize: "35px" }}
              >
                Cabinets Docs
              </Typography>
              <Typography
                variant="h5"
                textAlign={largeScreen ? "left" : "center"}
                sx={{ marginTop: "1rem", color: "#444444", fontSize: "22px" }}
              >
                Every {<strong>document</strong>} you need is here! You only
                need the {<strong>serial number </strong>}
                and the {<strong>password </strong>} you'll get on your mobile
                phone or e-mail*.
              </Typography>
              <ColorButton
                href="https://documents.eptarefrigeration.com/"
                variant="contained"
              >
                Documentation
              </ColorButton>
            </Stack>
          </Grid>
          <Grid item lg={4}>
            <Stack alignItems="center" justifyContent="center" spacing={2}>
              <a href="https://www.epta-service.com/service/">
                <img
                  style={largeScreen ? { maxWidth: "380px" } : { maxWidth: "300px" }}
                  alt="Epta Logo"
                  src={require("../assets/img/NFC_E-catalogue-spare-parts3.webp")}
                />
              </a>
              <Typography
                variant="h2"
                textAlign={largeScreen ? "left" : "center"}
                sx={{ marginTop: "1rem", color: "#444444", fontSize: "35px" }}
              >
                Spare Parts
              </Typography>
              <Typography
                variant="h5"
                textAlign={largeScreen ? "left" : "center"}
                sx={{ marginTop: "1rem", color: "#444444", fontSize: "22px" }}
              >
                Insert the {<strong>serial number </strong>} to order the spare
                parts of the cabinet, check{" "}
                {<strong>information, availability </strong>} and delivery
                dates.
              </Typography>
              <ColorButton
                href="https://www.epta-service.com/service/"
                variant="contained"
              >
                E-Catalogue
              </ColorButton>
            </Stack>
          </Grid>
          <Grid item lg={4}>
            <Stack alignItems="center" justifyContent="center" spacing={2}>
              <a href="https://eprel.ec.europa.eu/screen/product/refrigeratingappliancesdirectsalesfunction">
                <img
                  style={largeScreen ? { maxWidth: "380px" } : { maxWidth: "300px" }}
                  alt="Epta Logo"
                  src={require("../assets/img/NFC_Eprel.webp")}
                />
              </a>
              <Typography
                variant="h2"
                textAlign={largeScreen ? "left" : "center"}
                sx={{ marginTop: "1rem", color: "#444444", fontSize: "35px" }}
              >
                Energy Label
              </Typography>
              <Typography
                variant="h5"
                textAlign={largeScreen ? "left" : "center"}
                sx={{ marginTop: "1rem", color: "#444444", fontSize: "22px" }}
              >
                Make sure that the energy label of your cabinet is registered on
                the EU database. Use the {<strong>model identifier </strong>} on
                the energy label.**
              </Typography>
              <ColorButton
                href="https://eprel.ec.europa.eu/screen/product/refrigeratingappliancesdirectsalesfunction"
                variant="contained"
              >
                EPREL
              </ColorButton>
            </Stack>
          </Grid>
        </Grid>
        <Typography
          variant="subtitle1"
          sx={{ marginTop: "1rem", color: "#444444", fontSize: "14px" }}
        >
          *for Germany: please use only your e-mail address
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ color: "#444444", fontSize: "14px" }}
        >
          **If you have lost the energy label that has been delivered with your
          cabinet, please ask for a copy to technical.mktg@eptarefrigeration.com
        </Typography>
      </Container>
      <Container
        maxWidth={false}
        sx={{
          pt: "20px",
          pb: "20px",
          backgroundColor: `rgba(215, 121, 0, 0.3) !important;`,
        }}
      >
        <Grid
          container
          sx={{
            pt: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={2}
        >
          <Grid item lg={6}>
            <a href="https://play.google.com/store/apps/developer?id=Epta+SpA&hl=it">
              <img
                style={largeScreen ? {float: "right", maxWidth: "550px"}: {float: "right", width: "300px"}}
                alt="Epta Logo"
                src={require("../assets/img/NFC_Websitesandapps.webp")}
              />
            </a>
          </Grid>
          <Grid item lg={6}>
            <Typography
              variant="h2"
              textAlign={largeScreen ? "left" : "center"}
              sx={{ marginTop: "1rem", color: "#444444", fontSize: "44px" }}
            >
              More Docs and info on the cabinet are available on our catalogues
              websites & Apps
            </Typography>
            <Typography
              variant="h5"
              textAlign={largeScreen ? "left" : "center"}
              sx={{ marginTop: "2rem", color: "#444444", fontSize: "22px" }}
            >
              Find extra documentations and info you need. You just need log-in,
              to click on the cabinet model in the "our offer" section and
              you'll access:
            </Typography>
            <Typography sx={{ marginTop: "1rem", color: "#444444", fontSize: "22px" }} component="div">
              <ul>
                <li>User manual and cleaning instructions</li>
                <li>Disassembly manual</li>
                <li>Videos about cabinet installation</li>
                <li>Quick reference instructions</li>
                <li>Technical manual</li>
              </ul>
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            lg={2}
          >
            <a href="https://www.costan.com/en">
              <img
                style={largeScreen ? { maxWidth: "175px" } : { maxWidth: "300px" }}
                alt="Epta Logo"
                src={require("../assets/img/Costan.webp")}
              />
            </a>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            lg={2}
          >
            <a href="https://www.bonnetneve.com/en">
              <img
                style={largeScreen ? { maxWidth: "175px" } : { maxWidth: "300px" }}
                alt="Epta Logo"
                src={require("../assets/img/Bonnet Neve.webp")}
              />
            </a>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            lg={2}
          >
            <a href="https://www.eurocryor.com/en">
              <img
                style={largeScreen ? { maxWidth: "175px" } : { maxWidth: "300px" }}
                alt="Epta Logo"
                src={require("../assets/img/Eurocryor.webp")}
              />
            </a>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            lg={2}
          >
            <a href="https://www.misa-coldrooms.com/en">
              <img
                style={largeScreen ? { maxWidth: "175px" } : { maxWidth: "300px" }}
                alt="Epta Logo"
                src={require("../assets/img/Misa.webp")}
              />
            </a>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            lg={2}
          >
            <a href="https://www.iarp-plugin.com/en">
              <img
                style={largeScreen ? { maxWidth: "175px" } : { maxWidth: "300px" }}
                alt="Epta Logo"
                src={require("../assets/img/Iarp.webp")}
              />
            </a>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            lg={2}
          >
            <a href="https://www.kysorwarren.com/en">
              <img
                style={largeScreen ? { maxWidth: "175px" } : { maxWidth: "300px" }}
                alt="Epta Logo"
                src={require("../assets/img/Kysor-Warren.webp")}
              />
            </a>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false}>
        <Grid
          container
          sx={{
            pt: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={2}
        >
          <Grid item lg={12}>
            <Typography
              variant="h2"
              textAlign={largeScreen ? "left" : "center"}
              sx={{ marginTop: "1rem", color: "#444444", fontSize: "44px" }}
            >
              Do you need more assistance?
            </Typography>
            <Typography
              variant="h5"
              textAlign={largeScreen ? "left" : "center"}
              sx={{ marginTop: "1rem", color: "#444444", fontSize: "22px" }}
            >
              Contact your local referent:
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            pt: 10,
            pb: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={2}
        >
          {contactsArray.map((contact, index) => (
            <Grid
              key={index}
              item
              xs={12}
              lg={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  width: "400px",
                  padding: "10px",
                }}
              >
                <Typography textAlign={largeScreen ? "left" : "center"} variant="h4">{contact.country}</Typography>
                <Typography textAlign={largeScreen ? "left" : "center"} variant="h6" sx={{ marginTop: "1rem" }}>
                  {contact.address}
                </Typography>
                <Typography textAlign={largeScreen ? "left" : "center"} variant="h6" sx={{ marginTop: "1rem" }}>
                  {contact.phone}
                </Typography>
                <Typography textAlign={largeScreen ? "left" : "center"} variant="h6" sx={{ marginTop: "1rem" }}>
                  <a href={"mailto:" + contact.email}>{"📧 " + contact.email}</a>
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        sx={{
          pt: 3,
          backgroundColor: `rgba(215, 121, 0, 0.3) !important;`,
        }}
      >
        <Grid
          container
          sx={{
            pt: 10,
            pb: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
          spacing={2}
        >
          <Grid item lg={6}>
            <a href="https://play.google.com/store/apps/developer?id=Epta+SpA&hl=it">
              <img
                style={largeScreen ? { float: "right", maxWidth: "584px" } : { float: "right", maxWidth: "300px" }}
                alt="Epta Logo"
                src={require("../assets/img/NFC_Digital-transformation.webp")}
              />
            </a>
          </Grid>
          <Grid item lg={6}>
            <Typography
              variant="h2"

              textAlign={largeScreen ? "left" : "center"}
              sx={{ marginTop: "1rem", color: "#444444", fontSize: "44px" }}
            >
              Epta is working on its digital transformation
            </Typography>
            <Typography
              variant="h5"
              textAlign={largeScreen ? "left" : "center"}
              sx={{ marginTop: "1rem", color: "#444444", fontSize: "22px" }}
            >
              In the next period, using NFC technology you'll get inside the
              tools automatically{" "}
              {
                <strong>
                  without manually inserting the serial number of the cabinet.
                </strong>
              }
            </Typography>
            <Typography
              variant="h5"
              textAlign={largeScreen ? "left" : "center"}
              sx={{ marginTop: "1rem", color: "#444444", fontSize: "22px" }}
            >
              {<strong>Stay connected </strong>} and updated about the{" "}
              {<strong>upcoming tools:</strong>} this is a new way to connect
              easily and completely with {<strong>your fleet.</strong>}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{
          pt: 5,
          pb: 5,
          backgroundColor: `rgba(221, 221, 221, 1) !important`,
        }}
        maxWidth={false}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <a href="https://www.facebook.com/EptaSpA/">
            <FacebookIcon color="primary" sx={{ fontSize: "40px" }} />
          </a>
          <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FEpta_Group">
            <TwitterIcon
              fontSize="large"
              sx={{ fontSize: "40px", color: "#1DA1F2" }}
            />
          </a>
          <a href="https://www.youtube.com/user/EPTAspa">
            <YouTubeIcon
              fontSize="large"
              sx={{ fontSize: "40px", color: "#FF0000" }}
            />
          </a>
          <a href="https://www.linkedin.com/company/epta-group/">
            <LinkedInIcon
              fontSize="large"
              color="primary"
              sx={{ fontSize: "40px" }}
            />
          </a>
          <a href="https://www.instagram.com/epta_group/">
            <InstagramIcon sx={{ fontSize: "40px", color: "#E1306C" }} />
          </a>
        </Stack>
      </Container>
    </>
  );
}
