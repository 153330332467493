import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Equipment } from "../Equipment";

export function TagResolver() {
  let params = useParams();
  const { tagId } = params;
  const [nfcTag, setNfcTag] = useState({ data: null, isLoading: true });

  const fetchData = async (lat, lon) => {
    const payload = {
      queryStringParameters: {
        tag_id: tagId,
        latitude: lat,
        longitude: lon,
      },
    };
    const data = await API.post(
      "NFClandingAPI",
      "/getSerialNumberByTagId",
      payload
    );
    if (data && data.length > 0) {
      setNfcTag({ data: data[0], loading: false });
    } else {
      setNfcTag({ data: null, loading: false });
    }
  };

  function successGeolocation(position) {
    fetchData(position.coords.latitude, position.coords.longitude);
  }

  function errorGeolocation() {
    console.log("Geolocation is not active or not supported");
    fetchData(null, null);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        successGeolocation,
        errorGeolocation
      );
    } else {
      console.log("Geolocation not supported");
      fetchData(null, null);
    }
  }, [tagId]);

  if (nfcTag.isLoading) {
    return null;
  }
  if (!nfcTag.data) return <Equipment />;
  else if (nfcTag.non_epta) {
    return (
      <Equipment
        serialNumber={nfcTag.data.serial_number}
        non_epta={nfcTag.data}
      />
    );
  } else return <Equipment serialNumber={nfcTag.data.serial_number} />;
}
