import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export default function InfoAlert({ title, message }) {
  return (
    <Alert severity="info">
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
}
