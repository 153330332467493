import * as React from "react";
import Box from "@mui/material/Box";
import { createSvgIcon } from "@mui/material/utils";

const EnergyClassE = createSvgIcon(
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    x={-28}
    y={2}
    width={80}
    height={20}
    viewBox="0 0 104.7 57.5"
    style={{
      enableBackground: "new 0 0 104.7 57.5",
    }}
    xmlSpace="preserve"
  >
    <style>
      {
        ".st0{fill:#fff}.st5{fill:none;stroke:#1c1d00;stroke-width:2.218;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    <path className="st0" d="M.2.3H24V57H.2z" />
    <path
      style={{
        fill: "#fab900",
      }}
      d="M78.8.8H22.4v55.9h56.4l25.7-28z"
    />
    <path
      d="M78.3 57.5H.8c-.5 0-.8-.4-.8-.8V.8C0 .3.4 0 .8 0h77.4c.2 0 .5.1.6.3l25.7 28c.3.3.3.8 0 1.1l-25.7 28c-.1 0-.3.1-.5.1zM.8.8v55.9h77.4l25.7-28L78.2.7.8.8z"
      style={{
        fill: "#1d1d1b",
      }}
    />
    <path
      className="st0"
      d="M60.4 40.8v1c0 .3-.1.5-.1.7s-.1.3-.2.4-.3.1-.4.1H46.8c-.4 0-.8-.1-1.1-.4-.3-.3-.4-.7-.4-1.4V17.1c0-.6.1-1.1.4-1.4.3-.3.7-.4 1.1-.4h12.8c.1 0 .2 0 .3.1s.2.2.2.4c.1.2.1.4.1.7v2.1c0 .3-.1.5-.1.7-.1.2-.1.3-.2.4s-.2.1-.3.1h-9.1v6.8h7.7c.1 0 .2 0 .3.1s.2.2.2.4c.1.2.1.4.1.7v2c0 .3-.1.5-.1.7-.1.2-.1.3-.2.4s-.2.1-.3.1h-7.7v7.8h9.2c.1 0 .2 0 .3.1s.2.2.2.4.1.4.1.7c.1 0 .1.3.1.8z"
    />
    <path
      d="M60.4 39.7c0-.3-.1-.5-.1-.7-.1-.2-.1-.3-.2-.4-.1-.1-.2-.1-.3-.1h-9.2v-7.8h7.7c.1 0 .2 0 .3-.1s.2-.2.2-.4c.1-.2.1-.4.1-.6v-2c0-.3-.1-.5-.1-.7-.1-.2-.1-.3-.2-.4-.1-.1-.2-.1-.3-.1h-7.7v-6.8h9.1c.1 0 .2 0 .3-.1s.2-.2.2-.4c.1-.2.1-.4.1-.7 0-.3.1-.6.1-1s0-.8-.1-1.1c0-.3-.1-.5-.1-.7-.1-.2-.1-.3-.2-.4-.1-.1-.2-.1-.3-.1H46.8c-.4 0-.8.1-1.1.4-.3.3-.4.7-.4 1.4V41c0 .6.1 1.1.4 1.4.3.3.7.4 1.1.4h12.8c.1 0 .2 0 .3-.1s.2-.2.2-.4c.1-.2.1-.4.1-.7v-1c.2-.3.2-.6.2-.9z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 0.526,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M18.4 17.4c.1.3.1.5.2.6 0 .2 0 .3-.1.3-.1.1-.2.1-.4.1H16.4c-.2 0-.4 0-.5-.1-.1 0-.2-.1-.2-.1s-.1-.1-.1-.2l-.9-2.8H9.3L8.4 18c0 .1-.1.2-.1.3s-.1.1-.2.2c-.1 0-.3.1-.4.1H6.1c-.2 0-.3-.1-.4-.2s-.1-.2-.1-.4.1-.4.2-.6L10.1 5c0-.1.1-.2.2-.3s.2-.1.3-.2c.1 0 .3-.1.5-.1H13c.3 0 .4 0 .6.1.1 0 .2.1.3.2 0 0 .1.1.1.3l4.4 12.4zM11.9 7.1l-2 6h4l-2-6z"
      style={{
        fill: "#1d1c1b",
      }}
    />
    <path className="st5" d="M12.1 34.9v-12M15.2 26l-3.1-3.1L8.9 26" />
    <path
      d="M17.5 41.3v.5c0 .1 0 .3-.1.4 0 .1-.1.2-.1.2s-.1.1-.2.1-.2-.1-.4-.2-.4-.3-.8-.4c-.3-.1-.7-.3-1.1-.4-.4-.1-.9-.2-1.5-.2s-1.2.1-1.7.3-.9.6-1.3 1-.6.9-.8 1.5-.3 1.3-.3 2c0 .8.1 1.5.3 2.1s.5 1.1.8 1.5.8.7 1.3.9 1 .3 1.6.3c.3 0 .6 0 .9-.1s.5-.2.8-.3v-3.2h-2.4c-.1 0-.2-.1-.3-.2s-.1-.4-.1-.8v-.5c0-.1 0-.2.1-.3 0-.1.1-.1.1-.2 0 0 .1-.1.2-.1h4.3c.1 0 .2 0 .3.1.1 0 .2.1.2.2s.1.2.1.3v5.9c0 .2 0 .4-.1.6-.1.2-.2.3-.4.4s-.5.2-.8.3c-.3.1-.6.2-1 .3-.3.1-.7.1-1 .2-.3 0-.7.1-1 .1-1 0-1.9-.2-2.7-.5s-1.5-.8-2-1.4c-.6-.6-1-1.3-1.3-2.2s-.4-1.8-.4-2.9c0-1.1.2-2.1.5-3.1.3-.9.8-1.7 1.3-2.3.6-.6 1.3-1.1 2.1-1.4.8-.3 1.7-.5 2.7-.5.6 0 1.1 0 1.5.1.5.1.9.2 1.2.3.3.1.6.2.8.4.2.1.4.3.5.4.1.1.2.2.2.4v.4z"
      style={{
        fill: "#1d1c1b",
      }}
    />
  </svg>
);

export default EnergyClassE;
